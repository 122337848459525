<template>
    <div class="layui-row layui-col-space30 x-comm-imgword">
        <div class="layui-col-xs12 layui-col-sm6 layui-col-lg4" v-for="(item, index) in items" :key="index">
            <div class="x-img-box">
                <a :href="`/courses/${myUtils.formatSlug(item.id,item.name)}`"><img alt="课程图片" class="x-img" :src="item.img" /></a>
            </div>
            <div class="content">
                <a :href="`/courses/${myUtils.formatSlug(item.id,item.name)}`"><img alt="箭头" class="arrow" src="assets/img/arrow_right.svg" /></a>
                <p class="name"><a :href="`/courses/${myUtils.formatSlug(item.id,item.name)}`">{{ item.name }}</a></p>
                <p class="english">{{ item.english }}</p>
            </div>
        </div>
    </div>
</template>


<script setup>
import img1 from "/assets/img/index_courses_1.jpg"
import img2 from "/assets/img/index_courses_2.jpg"
import img3 from "/assets/img/index_courses_3.jpg"
import img4 from "/assets/img/index_courses_4.jpg"
import img5 from "/assets/img/index_courses_5.jpg"
import img6 from "/assets/img/index_courses_6.jpg"

let items = ref([
    {
        id : 1,
        name: "一般英语课程",
        english: "General English Course",
        img : img1,
    },
    {
        id : 2,
        name: "雅思英语课程",
        english: "IELTS English Course",
        img : img2,
    },
    {
        id : 3,
        name: "零基础英语课程",
        english: "Beginner English Course",
        img :img3
    },
    {
        id : 4,
        name: "商务英语课程",
        english: "Business English Course",
        img : img4
    },
    {
        id : 5,
        name: "口语强化课程",
        english: "Speaking Intensive Course",
        img : img5
    },
    {
        id : 6,
        name: "30+一般英语课程",
        english: "30+ General English Course",
        img : img6
    }
]);
</script>


