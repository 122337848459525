<template>
    <div class="layui-row layui-col-space30 x-comm-imgword">
        <div class="layui-col-xs12 layui-col-sm6 layui-col-lg4" v-for="item in items.slice(0, 6)">
            <div class="x-img-box">
                <a :href="`/schools/${myUtils.formatSlug(item.id, item.attributes.name)}`"><img alt="学校"
                        :src="myUtils.getUrlPic(item.attributes.pictures?.data?.[0], 'small')" class="x-img" /></a>
            </div>
            <div class="content">
                <a :href="`/schools/${myUtils.formatSlug(item.id, item.attributes.name)}`"><img alt="箭头" class="arrow"
                        src="assets/img/arrow_right.svg" /></a>
                <p class="name"><a :href="`/schools/${myUtils.formatSlug(item.id, item.attributes.name)}`">{{
                    item.attributes.name }}</a></p>
                <p class="english">{{ item.attributes.city.data.attributes.name }}</p>
                <p>{{ item.attributes.desc }}</p>
                <CommonSchoolFeature  :item="item"/>

            </div>
        </div>
    </div>
</template>

<script setup>



const props = defineProps({
    data: Object
})
const items = ref(props.data.data);
</script>

