<template>
    <div>
        <div class="layui-fluid x-banner x-bg-blue">
            <!-- <IndexBanner /> -->
            <IndexWanghao />
        </div>
        <div class="layui-fluid x-zhuanye">
            <div class="layui-container">
                <div class="layui-row x-head">
                    <div class="layui-col-xs12">
                        <p class="index-title-eng x-font-brit">Popular</p>
                        <h2 class="index-title-chn">热门语言学校</h2>
                        <!-- <img class="x-comm-title" src="assets/img/index_title_zhuanye.png" /> -->
                        <div class="x-more"><a href="/citys/1-伦敦">More ></a></div>
                    </div>
                </div>
                <IndexZhuanye :data="data.data.attributes.hotSchools" />

            </div>
        </div>
        <div class="layui-fluid x-citys x-bg-blue">
            <div class="layui-container">
                <div class="layui-row x-head">
                    <div class="layui-col-xs12">
                        <h2 class="index-title-chn">地区选择</h2>
                        <p class="index-title-eng x-font-brit">Region</p>
                        <!-- <div class="x-more">More ></div> -->
                    </div>
                </div>
                <IndexCitys />
            </div>
        </div>

        <div class="layui-fluid x-course x-bg-grey">
            <div class="layui-container">
                <div class="layui-row x-head">
                    <div class="layui-col-xs12">
                        <h2 class="index-title-chn">课程介绍</h2>
                        <p class="index-title-eng x-font-brit">Course</p>
                        <!-- <div class="x-more">More ></div> -->
                    </div>
                </div>
                <IndexCourses />
            </div>

        </div>


        <div class="layui-fluid x-youxue  x-bg-grey">
            <div class="layui-container">
                <div class="layui-row x-head">
                    <div class="layui-col-xs12">
                        <h2 class="index-title-chn">主题游学</h2>
                        <p class="index-title-eng x-font-brit">Tour</p>
                        <div class="x-more"><a href="/projects">More ></a></div>
                    </div>
                </div>
                <IndexYouxue :data="data.data.attributes.projects" />
            </div>
        </div>

        <div class="layui-fluid x-visits x-bg-white">
            <div class="layui-container">
                <div class="layui-row x-head">
                    <div class="layui-col-xs12">
                        <h2 class="index-title-chn">实地院校探访</h2>
                        <p class="index-title-eng x-font-brit">Visits</p>
                        <div class="x-more"><a href="/citys/1-伦敦">More ></a></div>
                    </div>
                </div>
                <IndexVisits :data="data.data.attributes.visits" />
            </div>

        </div>


        <div class="layui-fluid x-fuwu x-bg-grey">
            <div class="layui-container">
                <div class="layui-row x-head">
                    <div class="layui-col-xs12">
                        <h2 class="index-title-chn">服务流程</h2>
                        <p class="index-title-eng x-font-brit">Porcess</p>
                    </div>
                </div>
                <IndexFuwu />
            </div>
        </div>


        <div class="layui-fluid x-hezuo">
            <div class="layui-container">
                <div class="layui-row x-head">
                    <div class="layui-col-xs12">
                        <h2 class="index-title-chn">合作机构及认证</h2>
                        <p class="index-title-eng x-font-brit">Partners</p>
                    </div>
                </div>
            </div>
            <IndexHezuo />
        </div>



    </div>
</template>
<script setup>
import qs from 'qs';
const query = qs.stringify({
    populate : {
        hotSchools : {
            fields : ["name","desc","scale",'found_year','score'],
            populate : {
                city : {
                    fields : ["name"],
                },
                pictures : {
                    fields : ["width", "hash","ext"],
                }
            }
        },
        projects : {
            fields : ["name"],
            populate : {
                photos : {
                    fields : ["width", "hash","ext"]
                }
            }
        },
        visits : {
            fields : ["name","desc"],
            populate : {
                pictures : {
                    fields : ["width", "hash","ext"],
                }
            }
        }
    }
})
const url = '/api/fw/homepages/1?'+ query;
console.log('url', url);
const { data } = await useFetch(url);

useSeoMeta({
    'ahrefs-site-verification' : "4d61e5ceab39c8cc0ad06afcbae936d0348d4132dde7abb93a619c9dc74f50e1",
    'baidu-site-verification' : "codeva-oRgY494rfi"
})
</script>
<style scoped>
.layui-fluid {
    padding-bottom: 80px;
}

.x-banner {
    padding-bottom: 0px;
    padding-left: 0;
    padding-right: 0;
    width: 100%;
    overflow: hidden;
}

.x-head {
    padding: 120px 0 48px 0;
}

.x-hezuo .x-head {
    padding: 80px 0 100px 0;
}

.index-title-eng {
    position: absolute;
    color: #448AC9;
    font-size: 48px;
    top: -38px;
    left: 0px;
}

.index-title-chn {
    font-size: 40px;
    margin-top: -24px;
    padding-left: 14px;
    padding-top: 40px;
}

.x-more {
    position: absolute;
    bottom: 0;
    right: 0;
    color: var(--text-text-secondary, #787D83);
    font-family: "HarmonyOS Sans SC";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
</style>

<style>
/* 首页图文模块共用样式 */
.x-comm-imgword .x-img {
    object-fit: cover;
    object-position: center;
    width: 100%;
    height: 100%;
}

.x-comm-imgword .x-img-box {
    width: 100%;
    border-radius: 16px 16px 0 0;
    background: #D9D9D9;
    overflow:hidden;
    height:180px;
}

.x-comm-imgword .content {
    padding: 24px 16px;
    background-color: white;
    border-radius: 0 0 16px 16px;
    position: relative;
}

.x-comm-imgword .name {
    margin-right:36px;
    color: #000;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.x-comm-imgword .english {
    color: var(--text-text-secondary, #787D83);
    font-family: "HarmonyOS Sans SC";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.x-comm-imgword .arrow {
    position: absolute;
    top: 24px;
    right: 16px;
    width: 32px;
    height: 32px;
}

.x-comm-title {
    width: auto;
}
</style>