<template>
    <div class="layui-container">
        <div class="box x-bg-blue">
            <p class="fangxin">放心出发</p>
            <p class="aishang">爱上游学</p>
            <p class="aoyun-box"><span class="aoyun">奥运冠军</span><span class="tuijian">推荐</span></p>
            <p class="yu">与小鸥一起到英国看世界</p>
            <p class="dashi-box"><span class="dashi">慧行小鸥品牌形象大使</span><span class="tiaoshui">奥运跳水冠军</span><span
                    class="wang">汪皓</span></p>
            <img alt="签名" class="qianming" src="/assets/img/index_banner_qianming.png" />
            <img alt="汪皓" class="zhaopian" src="/assets/img/index_banner_wanghao.png" />
        </div>

    </div>
</template>
<style scoped>

.box {
    position: relative;
    height:740px;
}
@media screen and (min-width: 750px) {
  .box{
  }
}

.fangxin {
    color: #192D61;
    font-size: 76px;
    font-style: normal;
    font-weight: 900;
    line-height: 80px;
    position: absolute;
    left: 50%;
    top: 44px;
    margin-left: -152px;
}

.aishang {
    color: #192D61;
    font-size: 76px;
    font-style: normal;
    font-weight: 900;
    line-height: 80px;
    position: absolute;
    left: 50%;
    top: 128px;
    margin-left: -152px;
}

.aoyun-box {
    position: absolute;
    top: 228px;
    left: 50%;
    margin-left: -96px;
}

.aoyun {
    color: #FFBB50;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.tuijian {
    color: var(--text-text-primary, #2B2F33);
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.yu {
    position: absolute;
    top:280px;
    left:50%;
    margin-left:-176px;
    color: var(--text-text-primary, #2B2F33);
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.dashi-box{
    position: absolute;
    top:340px;
    left:50%;
    margin-left:-156px;
}
.dashi {
    color: var(--text-text-secondary, #787D83);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.tiaoshui {
    color: var(--text-text-secondary, #787D83);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.wang {
    color: var(--text-text-primary, #2B2F33);
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.qianming{
    position:absolute;
    top:400px;
    left:50%; 
    margin-left:-240px;
    width:148px;
}
.zhaopian{
    position:absolute;
    top:320px;
    left:50%;
    margin-left:-330px;
    width:760px;
}

@media screen and (min-width: 768px) {
    .fangxin{
        top:88px;
        left:50%;
        margin-left:-304px;
    }
    .aishang{
        top:88px;
        left:50%;
        margin-left:0;
    }
    .aoyun-box{
        top:188px;
        margin-left:-304px;
    }
    .yu{
        top:188px;
        margin-left:-100px;
    }
    .dashi-box{
        top:268px;
        margin-left:-304px;
    }
    .dashi-box span{
        display:block;
        margin-top:6px;
    }
    .qianming{
        margin-left:-304px;
        width:241px;
    }
    .zhaopian{
        width:934px;
        top:230px;
        margin-left:-304px;
    }
}
@media screen and (min-width: 1200px) {
    .fangxin{
        left:0;
        margin-left:0;
        top:120px;
    }
    .aishang{
        top:120px;
        margin-left:320px;
        left:0;
    }
    .aoyun-box{
        top:216px;
        left:0;
        margin-left:0;
    }
    .yu{
        top:216px;
        left:200px;
        margin-left:0;
    }
    .dashi-box{
        top:120px;
        left:auto;
        right:0;
        margin-left:0;
        text-align: right;
    }
    .qianming{
        width:241px;
        left:0;
        margin-left:0;
        top:240px;
    }
    .zhaopian{
        top:0px;
        margin-left:0px;
        left:400px;
        width:934px;
    }
    .box{
        height:632px;
    }
}

</style> 