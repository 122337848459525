<template>
    <div class="layui-row layui-col-space30 x-comm-imgword">
        <div class="layui-col-xs12 layui-col-sm6 layui-col-lg4" v-for="(item, index) in items.slice(0,3)" :key="index">
            <div class="x-img-box">
                <a :href="`/projects/${myUtils.formatSlug(item.id,item.attributes.name)}`"><img class="x-img" :src="myUtils.getUrlPic(item.attributes.photos?.data?.[0],'medium')" alt="游学" /></a>
            </div>
            <div class="content">
                <a :href="`/projects/${myUtils.formatSlug(item.id,item.attributes.name)}`"><img alt="箭头" class="arrow" src="assets/img/arrow_right.svg" /></a>
                <p class="name"><a :href="`/projects/${myUtils.formatSlug(item.id,item.attributes.name)}`">{{ item.attributes.name }}</a></p>
                <p class="english">{{ item.attributes.english }}</p>
            </div>

        </div>
    </div>
</template>


<script setup>

// let items = ref([

//     {
//         id : 2,
//         name: "Bayswater College 奢侈品管理课程",
//         english: "",
//         img : "https://www.xiaoou.cc/uploads/small_Bayswater_College_8_067eef5817.png"
//     },
//     {
//         id : 3,
//         name: "Bayswater College 数字营销认证课程",
//         english: "",
//         img : "https://www.xiaoou.cc/uploads/small_Bayswater_College_4_d862e9891f.png"
//     },
//     {
//         id : 1,
//         name: "伦敦IHL亲子冬季课程",
//         english: "",
//         img : "https://www.xiaoou.cc/uploads/1234_6534c9b38e.jpg"
//     },
// ]);

const props = defineProps({
    data: Object
})
const items = ref(props.data.data);
console.log('visits', items.value)
</script>


