<template>
    <div class="layui-row layui-col-space24">
        <div class="layui-col-xs12 layui-col-sm6 layui-col-lg2 box" v-for="(item, index) in items" :key="index">
            <div class="index">
                <div class="num">{{ index + 1 }}</div>
            </div>
            <div class="content">
                <p class="img"><img alt="服务" class="" :src="item.img" /></p>
                <p class="title">{{ item.title }}</p>
                <p class="desc">{{ item.desc1 }}</p>
                <p class="desc">{{ item.desc2 }}</p>
                <p class="desc">{{ item.desc3 }}</p>
            </div>
        </div>
    </div>
</template>


<script setup>
import img1 from "/assets/img/index_fuwu_1.svg";
import img2 from "/assets/img/index_fuwu_2.svg";
import img3 from "/assets/img/index_fuwu_3.svg";
import img4 from "/assets/img/index_fuwu_4.svg";
import img5 from "/assets/img/index_fuwu_5.svg";

let items = ref([
    {
        img: img1,
        title: "顾问咨询",
        desc1: "与专业顾问一对一沟通，了解学习需求和目标，获得个性化的建议和指导。",

    },
    {
        img: img2,
        title: "选取课程/方案",
        desc1: "根据顾问的建议和个人需求，选择最适合的课程、学习方案以及对应签证。"
    },
    {
        img: img3,
        title: "项目报名",
        desc1: "准备签证材料、填写院校报名表，提交所需资料，并完成相关的报名手续和费用支付。"
    },
    {
        img: img4,
        title: "行前说明",
        desc1: "小鸥导师提供行前说明会文件，了解出行事项、住宿安排和当地生活信息。"

    },
    {
        img: img5,
        title: "安心出发",
        desc1: "准备好行李和游学的心，在导师的协助下安心出发，迎接一段难忘的旅程。"
    }
]);
</script>

<style scoped>
.content {
    border-radius: 16px;
    background: var(--text-text-white-static, #FFF);
    padding: 32px 15px;
}

.img {
    margin-bottom: 32px;
}

.index {
    position: absolute;
    top: -16px;
    right: 28px;
    color: white;
    text-align: center;
    width: 56px;
    height: 56px;
    border-radius: 50%;
    background-color: #192d61;
    border: 4px solid #f9f9f9;
    box-sizing: border-box;
}

.num {
    margin-top: 6px;
    color: var(--text-text-white-static, #FFF);
    font-family: "HarmonyOS Sans SC";
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

@media screen and (min-width: 1200px),screen and (min-width: 1400px) {
    .box {
        width: 20%;
    }
}

.box{
    margin-top:20px;
}
.title {
    color: var(--text-text-primary, #2B2F33);
    font-family: "HarmonyOS Sans SC";
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 12px;
}

.desc {
    color: var(--text-text-secondary, #787D83);
    font-family: "HarmonyOS Sans SC";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22.4px;
}
</style>


