<template>
    <div class="layui-row layui-col-space24 row">
        <div class="layui-col-xs12 layui-col-sm12 layui-col-lg12 box">
            <div class="content">
                <div class="x-imgbox timg">
                    <a :href="`/schools/${myUtils.formatSlug(items[0].id, items[0].attributes.name)}`"><img alt="访问"
                            class="img" :src="myUtils.getUrlPic(items[0].attributes.pictures?.data?.[0], 'large')" /></a>
                    <a :href="`/schools/${myUtils.formatSlug(items[0].id, items[0].attributes.nam)}`"><img alt="播放"
                            class="x-play" src="assets/img/index_visits_play.svg" /></a>
                    <!-- <iframe class="iframe" :src="myUtils.getUrlVideo(items[0].video)" scrolling="no" border="0" frameborder="no" framespacing="0" allowfullscreen="true"></iframe> -->
                </div>
                <p class="name">{{ items[0].attributes.name }} </p>
                <p class="desc">{{ items[0].attributes.desc }}</p>
            </div>
        </div>
    </div>
    <div class="layui-row layui-col-space24 row">
        <div class="layui-col-xs12 layui-col -sm12 layui-col-lg4 box" v-for="(item, index) in items.slice(1, 4)"
            :key="index">
            <div class="content">
                <div class="x-imgbox bimg">
                    <a :href="`/schools/${myUtils.formatSlug(item.id, item.attributes.name)}`"><img alt="访问" class="img"
                            :src="myUtils.getUrlPic(item.attributes.pictures?.data?.[0], 'medium')" /></a>
                    <a :href="`/schools/${myUtils.formatSlug(item.id, item.attributes.name)}`"><img alt="播放" class="x-play"
                            src="assets/img/index_visits_play.svg" /></a>
                </div>
                <p class="name">{{ item.attributes.name }} </p>
                <p class="desc">{{ item.attributes.desc }}</p>
            </div>
        </div>
    </div>
</template>


<script setup>


// let items = ref([
//     {
//         id : 1,
//         name : "International House伦敦",
//         img: "https://www.xiaoou.cc/uploads/large_54_f53b5054c7.jpeg",
//         desc: `IH伦敦国际语言教育机构是一所成立于1953年的教育信托机构，凭借其全球性的教学经验及现代多种语言教育领域内的专业知识，致力于在世界各地进行语言培训和教师培训。每年接待来自约150个国家的7000多名学生，让学生们有机会接触到来自不同国家和有许多不同的背景的人。`
//     },
//     {
//         img: "https://www.xiaoou.cc/uploads/medium_18_1024x803_8423cd1ce0.jpg",
//         id : "2",
//         name : "Rose of York伦敦",
//         desc: "Rose of York位於伦敦市中心牛津街，是伦敦最繁荣时尚的区域。附近有很多商店、餐厅、咖啡厅等，距公交站、地铁站仅100米，生活和交通都非常便利。学校附近文化气息也很浓厚，周围有许多剧院，到国家美术馆和大英博物馆都只需要步行10分钟。"
//     },
//     {
//         id : "3",
//         name : "Speak up London伦敦",
//         img: "https://www.xiaoou.cc/uploads/33abd4d6_0d28_4605_82cc_c18e12c107cb_44ceb26b2f.png",
//         desc: "学校位于伦敦市中心的牛津街，是欧洲最繁忙的购物街之一，每天约有50万游客。Speak Up的公共交通非常发达，可以探索伦敦的热点地区。学校离牛津广场地铁站只有5分钟的步行路程，地铁站有环线，贝克鲁线和维多利亚线的连接，这将给学生提供一个非常好的基地去探索这个城市。从另一个方向步行5分钟，学生就可以到达托特纳姆法院路地铁站，那里有环线和北线的连接。对于喜欢步行游览的学生来说，向南步行20分钟就可以到达特拉法加广场、考文特花园和著名的泰晤士河。"
//     },
//     {
//         id : "4",
//         name : "ICE伦敦",
//         img: "https://www.xiaoou.cc/uploads/10002_bcb29a8d9b.jpeg",
//         desc: "伦敦伊斯灵顿英语中心 (ICE) 位于伊斯灵顿南部充满活力的传统 Angel 街区，步行即可到达众多娱乐场所、国际餐厅、传统酒吧等。学校附近还有几个景点和名胜古迹，包括阿尔梅达剧院、Angel 喜剧俱乐部、库尔佩珀社区花园、巴纳德公园等。学校全年都有定期和多样化的社交活动，包括参观博物馆、剧院之夜、夜间外出跳舞、涂鸦之旅、卡拉 OK 之夜、野餐、谋杀谜团之旅、参观空中花园等等。"
//     }
// ]);


const props = defineProps({
    data: Object
})
const items = ref(props.data.data);
</script>

<style scoped>
.iframe {
    width: 100%;
    height: 675px;
}
.name{
    margin-top:24px;
    margin-bottom:10px;
    font-weight: bold;
    font-size:24px;
}
.desc {
    /* margin-top: 32px; */
    color: var(--text-text-secondary, #787D83);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1.5em;
    /* 根据实际情况调整行高 */
    height: 4.5em;
    /* 3行的高度，行高的倍数 */
}

.timg {
    height: 675px;
}

.bimg {
    height: 223px;
}</style>


