<template>
    <div class="layui-row layui-col-space26 container x-comm-imgword">
        <div class="layui-col-xs12 layui-col-sm6 layui-col-lg3 item" v-for="(item, index) in items" :key="index">
            <div class="x-commimgword">
                <p class="img-eng x-font-brit x-color-white">{{ item.english }}</p>
                <div class="x-img-box"><a :href="`/citys/${myUtils.formatSlug(item.id, item.name)}`"><img alt="学校照片" class="x-img" :src="item.img" /></a></div>
            </div>
            <div class="content">
                <a :href="`/citys/${myUtils.formatSlug(item.id, item.name)}`"><img alt="箭头" class="arrow" src="assets/img/arrow_right.svg" /></a>
                <p class="name"><a :href="`/citys/${myUtils.formatSlug(item.id, item.name)}`">{{ item.name }}</a></p>
                <p class="english">{{ item.english }}</p>
                <p class="desc">{{ item.desc1 }}</p>
                <p class="desc">{{ item.desc2 }}</p>
                <p class="desc">{{ item.desc3 }}</p>
            </div>

        </div>
    </div>
</template>
 
<script setup>
import img_london from "/assets/img/index_citys_london.jpg";
import img_cambridge from "/assets/img/index_citys_cambridge.jpg";
import img_brighton from "/assets/img/index_citys_brighton.jpg";
import img_manchester from "/assets/img/index_citys_manchester.jpg";

let items = ref([
    {
        id : "1",
        name: "伦敦",
        english : "London",
        img: img_london,
        desc1: "国际化都市、丰富的历史和文化景点。",
        desc2: "众多语言学校和课程。",
        desc3 : "教学质量高。"
    },
    {
        id : "2",
        name: "剑桥",
        english: "Cambridge",
        img: img_cambridge,
        desc1: "优秀的教育体系。",
        desc2 : "丰富的学术资源。",
        desc3 : "大学城市，专心学习和体验生活氛围。"
    },
    {
        id : "3",
        name: "布莱顿",
        english: "Brighton",
        img: img_brighton,

        desc1: "创意产业和艺术文化活动丰富。",
        desc2 : "伦敦近郊海边小镇.",
        desc3 : "惬意的学习和生活环境。"
    },
    {
        id : 4,
        name: "曼切斯特",
        img: img_manchester,
        english: "Manchester",
        desc1: "拥有丰富的多元文化和音乐活动。",
        desc2:"提供真实的语言实践环境。",
        desc3: "活力的城市。"
    },

]);
</script>
<style scoped>
.x-comm-imgword .x-img-box{
    height:320px;
}
.x-commimgword{
    position: relative;
}
.x-img{
    height:320px;
}
.img-eng{
    position: absolute;
    top:50%;
    font-size:48px;
    left:50%;
    transform: translate(-50%, -50%);
}
.english {
    margin-bottom: 16px;
}
.x-imgbox img{
    height:320px
}
.desc {
    color: var(--text-text-secondary, #787D83);
    font-family: "HarmonyOS Sans SC";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
    /* 22.4px */
}
</style>