<template>
    <div class="layui-container container">
        <div class="layui-row layui-col-space32 row">
            <div class="layui-col-xs6  layui-col-sm4 layui-col-lg2 box" v-for="(item, index) in items" :key="index">
                <div class="x-imgbox">
                    <img alt="合作" class="img" :src="item.img" />
                </div>
            </div>
        </div>
    </div>
</template>


<script setup>

import qs from 'qs';

const query = qs.stringify({
    populate: {
        pic: {
            fields: ['width', 'hash', 'ext']
        }
    },
    fields: "name"
})
const url = '/api/fw/agencies/?' + query;
console.log('url', url);
const { data } = await useFetch(url);
const aData = [];
data?.value?.data?.forEach(element => {
    aData.push({ img: myUtils.getUrlPic(element.attributes.pic.data, 'small') });
});
const items = ref([]);
items.value = aData;



</script>


<style scoped>
/* .img {
    width: 180px;
} */

.box {
    text-align: center;
    height: 150px;
}

.box>img {
    /* width: 180px;
    max-height: 100px; */
}

@media screen and (min-width: 1200px),
screen and (min-width: 1400px) {
    .container {
        width: 100%;
        overflow: hidden;
        height: 150px;
    }

    .box {
        width: 212px;
        margin: 0 24px;
    }

    .row {
        /* margin-left: -100px; */
        width: 120%;
    }
}

.x-imgbox {
    width:100%;
    height: 100%;
    padding:4px;
    border:1px solid #e0e0e0;
    box-sizing: border-box;
    border-radius: 0;
    border :0;
}
.x-imgbox img{
    object-fit:contain;
}
</style>
